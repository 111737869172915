import * as THREE from "three";

const humanStand = () => {
  // tube geometry for camera movement animation
  let pipeSpline = new THREE.CatmullRomCurve3([
    new THREE.Vector3(14.8652, 32.9998, -6.49185),
    new THREE.Vector3(20.5216, 32.5945, 0.813831),
    new THREE.Vector3(28.0619, 29.3627, 10.164),
    new THREE.Vector3(35.5669, 22.6827, 17.9216),
    new THREE.Vector3(40.4383, 8.68989, 26.5702),
    new THREE.Vector3(38.846, -4.75159, 31.2569),
    new THREE.Vector3(30.5957, -17.0314, 33.8244),
    new THREE.Vector3(12.8381, -26.4493, 34.136),
    new THREE.Vector3(-6.2308, -33.0746, 33.6699),
    new THREE.Vector3(-23.6964, -30.4207, 14.815),
    new THREE.Vector3(-31.3026, -18.7443, -2.66945),
    new THREE.Vector3(-37.7269, -8.06838, -16.129),
    new THREE.Vector3(-37.9757, -3.55331, -20.4425),
    new THREE.Vector3(-35.7074, 0.913048, -21.3536),
    new THREE.Vector3(-31.7932, 2.79949, -20.4053),
    new THREE.Vector3(-26.3579, 1.50805, -15.3775),
    new THREE.Vector3(-25.6137, -8.53149, -6.55324),
    new THREE.Vector3(-22.2108, -20.1177, 5.29171),
    new THREE.Vector3(-16.2342, -27.4076, 14.8455),
    new THREE.Vector3(-2.90318, -27.267, 24.4673),
    new THREE.Vector3(10.901, -18.2159, 29.4572),
    new THREE.Vector3(20.2203, -8.18538, 30.4379),
    new THREE.Vector3(26.2484, 1.92549, 29.1929),
    new THREE.Vector3(33.389, 16.3355, 26.843),
    new THREE.Vector3(36.9722, 30.5457, 25.2953),
    new THREE.Vector3(37.2334, 38.6026, 26.7072),
    new THREE.Vector3(32.1881, 43.227, 32.1869),
    new THREE.Vector3(22.9303, 41.9171, 35.0045),
    new THREE.Vector3(15.1208, 33.3282, 29.9932),
    new THREE.Vector3(6.95571, 18.1453, 13.5123),
    new THREE.Vector3(2.56212, 8.33505, 2.78985),
    new THREE.Vector3(0.506925, 1.68393, -4.81493),
    new THREE.Vector3(-2.22784, -7.67207, -14.7094),
    new THREE.Vector3(-1.64231, -13.6923, -22.6194),
    new THREE.Vector3(4.479, -15.3107, -29.2883),
    new THREE.Vector3(11.8091, -13.1197, -35.3387),
    new THREE.Vector3(20.3793, -7.65231, -37.1522),
    new THREE.Vector3(24.8359, 3.34385, -37.7158),
    new THREE.Vector3(25.8896, 15.3655, -35.0512),
    new THREE.Vector3(22.9722, 25.7977, -29.5478),
    new THREE.Vector3(18.7697, 33.3126, -21.4836),
    new THREE.Vector3(12.5446, 39.243, -9.28498),
    new THREE.Vector3(7.66737, 40.1148, 4.45466),
    new THREE.Vector3(5.83371, 37.6548, 16.1551),
    new THREE.Vector3(2.24585, 30.7398, 26.6894),
    new THREE.Vector3(-1.90157, 20.7995, 34.1625),
    new THREE.Vector3(-5.93563, 13.0682, 38.2609),
    new THREE.Vector3(-9.65675, 3.50126, 41.8212),
    new THREE.Vector3(-15.7445, -7.50749, 41.5798),
    new THREE.Vector3(-22.2725, -16.6806, 36.5467),
    new THREE.Vector3(-27.2141, -21.6685, 30.9927),
    new THREE.Vector3(-31.8197, -24.7596, 19.8817),
    new THREE.Vector3(-32.7013, -22.3514, 7.82813),
    new THREE.Vector3(-32.9423, -18.6717, -6.60217),
    new THREE.Vector3(-32.37, -14.2615, -16.5718),
    new THREE.Vector3(-30.5073, -10.5619, -23.8907),
    new THREE.Vector3(-27.5566, -5.68121, -30.0171),
    new THREE.Vector3(-23.1397, 0.21678, -34.9474),
    new THREE.Vector3(-17.2732, 7.02165, -36.8397),
    new THREE.Vector3(-10.1218, 16.0699, -33.4315),
    new THREE.Vector3(-1.08787, 25.7007, -23.7841),
  ]);
  pipeSpline.closed = true;

  return pipeSpline;
};

export default humanStand;
