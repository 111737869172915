import React from 'react';

const WebGuru = () => {
  return (
    <svg
      width="29"
      height="17"
      viewBox="0 0 29 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000505675 0.051027C0.013263 0.0807941 0.604354 2.07094 1.31451 4.47357L2.60726 8.84509H3.44499H4.28272L4.46983 8.25825C4.57614 7.93932 5.00989 6.57428 5.43514 5.23051C5.86463 3.88674 6.22609 2.77259 6.2431 2.75133C6.25586 2.73432 6.71087 4.09936 7.24668 5.78333L8.22049 8.84509H9.09224H9.964L11.2355 4.47357C11.9371 2.07094 12.5197 0.0807941 12.5282 0.051027C12.541 0.00850249 12.3411 -2.41399e-06 11.5587 0.00850249L10.5678 0.0212598L9.89596 2.65778C9.0965 5.78758 9.08799 5.83436 9.06673 5.8131C9.05822 5.80459 8.73504 4.79251 8.34806 3.5678C7.96109 2.3431 7.5486 1.0376 7.42954 0.667632L7.21691 -2.41399e-06H6.31114H5.40112L4.97587 1.3055C4.74199 2.02842 4.32525 3.30415 4.05309 4.14188C3.75117 5.05616 3.5428 5.643 3.52153 5.60898C3.50452 5.57496 3.18134 4.29922 2.79862 2.77259L2.10972 -2.41399e-06H1.04661C0.213128 -2.41399e-06 -0.0122517 0.0127549 0.000505675 0.051027Z"
        fill="white"
      />
      <path
        d="M13.5148 4.42255V8.84509H16.6403H19.7659V7.95208V7.05906H17.6184H15.4709V6.14479V5.23051H17.3845H19.2981V4.3375V3.44448H17.3845H15.4709V2.61525V1.78603H17.4908H19.5107V0.893012V-2.41399e-06H16.5127H13.5148V4.42255Z"
        fill="white"
      />
      <path
        d="M21.3394 4.42255V8.84509H23.372C25.8852 8.84509 26.2254 8.80257 27.0589 8.38583C27.4076 8.21148 27.9009 7.73946 28.0455 7.44178C28.3601 6.79116 28.3474 5.94067 28.0114 5.31131C27.7648 4.84779 27.195 4.41404 26.6719 4.29497C26.3275 4.21418 26.3232 4.20992 26.5784 4.10361C27.5012 3.72514 27.9689 2.91718 27.8414 1.9051C27.7818 1.42882 27.6627 1.14816 27.4076 0.867497C27.1057 0.535806 26.825 0.357203 26.3785 0.216873C25.7874 0.0255123 25.4089 -2.41399e-06 23.287 -2.41399e-06H21.3394V4.42255ZM25.2431 1.72224C25.4897 1.79878 25.7321 1.98589 25.8342 2.18576C25.8767 2.2623 25.9065 2.41964 25.9065 2.57273C25.9065 2.88741 25.7832 3.13405 25.5408 3.2999C25.2686 3.49126 25.0177 3.52953 24.0949 3.52953H23.253V2.59399V1.65845H24.1375C24.8136 1.65845 25.0773 1.67546 25.2431 1.72224ZM25.6216 5.2943C25.8725 5.37935 26.1574 5.60473 26.2509 5.78758C26.2892 5.85137 26.3147 6.02147 26.3147 6.16605C26.3147 6.48498 26.2169 6.69335 25.966 6.90598C25.6726 7.15687 25.5068 7.18664 24.3076 7.18664H23.253V6.18306V5.17948L24.3288 5.19649C25.2261 5.2135 25.4345 5.22626 25.6216 5.2943Z"
        fill="white"
      />
      <path
        d="M3.3301 9.58503C2.39882 9.72961 1.57384 10.1804 1.03803 10.8267C0.242824 11.792 0.000433986 13.2591 0.417174 14.5604C0.812652 15.7894 1.7652 16.6186 3.10897 16.9035C4.17209 17.1331 5.67745 16.9418 6.76608 16.4485L7.05099 16.3167L7.04249 14.4626L7.02973 12.6085L5.63918 12.5958L4.24438 12.5873V13.3102V14.0331H4.88225H5.52011V14.6242V15.2153L5.32025 15.3003C4.87374 15.4917 4.62285 15.5385 4.09554 15.5385C3.34286 15.5427 2.9006 15.3684 2.44984 14.8921C2.0246 14.4498 1.85875 13.9906 1.85875 13.2676C1.85875 12.5107 2.05011 12.0217 2.52639 11.5667C3.04944 11.0649 3.73834 10.882 4.58457 11.0181C4.92902 11.0734 5.25646 11.218 5.55838 11.4519L5.78802 11.6305L6.16649 11.2265C6.37486 11.0053 6.6215 10.7332 6.71505 10.6226L6.88515 10.4228L6.65977 10.2527C5.89008 9.67433 4.52504 9.40217 3.3301 9.58503Z"
        fill="white"
      />
      <path
        d="M8.41187 12.0514C8.41187 13.9098 8.42462 14.4753 8.4714 14.722C8.65425 15.666 9.14754 16.3337 9.92148 16.6909C10.419 16.9205 10.738 16.9843 11.4098 16.9843C12.103 16.9843 12.4177 16.9205 12.9407 16.6696C13.5361 16.3804 13.9783 15.8872 14.208 15.245C14.3908 14.7262 14.4078 14.4753 14.4078 12.0174V9.69559H13.6211H12.8387L12.8259 12.0855C12.8132 14.4711 12.8132 14.4796 12.7153 14.688C12.5708 15.0069 12.3581 15.2365 12.0605 15.3939C11.8181 15.5257 11.7585 15.5385 11.4311 15.5427C11.1037 15.5427 11.0399 15.5299 10.7847 15.4024C10.4658 15.2493 10.2617 15.0282 10.1043 14.688C10.0065 14.4796 10.0065 14.4711 9.99378 12.0855L9.98102 9.69559H9.19857H8.41187V12.0514Z"
        fill="white"
      />
      <path
        d="M15.8536 13.2676V16.8397H16.6403H17.427V15.4151V13.9863L17.8183 13.9991L18.2137 14.0118L18.9494 15.4279L19.6893 16.8397H20.6206C21.1309 16.8397 21.5519 16.8312 21.5519 16.8227C21.5519 16.8142 21.1479 16.1423 20.6589 15.3258C19.8892 14.0501 19.7744 13.8375 19.8382 13.8119C19.8807 13.7949 20.021 13.7397 20.1529 13.6929C20.7737 13.4675 21.1394 12.9657 21.2628 12.1833C21.318 11.8133 21.267 11.3328 21.1267 10.9543C20.9991 10.6184 20.6206 10.2186 20.2677 10.0443C19.6723 9.75511 19.1748 9.69558 17.291 9.69558H15.8536V13.2676ZM19.0472 11.0989C19.4427 11.2094 19.6511 11.4774 19.6511 11.8643C19.6511 12.2003 19.5533 12.3661 19.2513 12.515C19.026 12.6255 18.9792 12.634 18.2223 12.6595L17.427 12.685V11.8728V11.0564H18.1627C18.5624 11.0564 18.9622 11.0776 19.0472 11.0989Z"
        fill="white"
      />
      <path
        d="M22.3683 12.1918C22.3854 14.6497 22.3854 14.6965 22.4832 15.0112C22.8276 16.1508 23.6271 16.8057 24.8645 16.9716C25.2813 17.0268 25.8596 16.9971 26.2721 16.8993C27.029 16.7249 27.7137 16.2019 28.0241 15.564C28.3388 14.9219 28.326 15.0197 28.343 12.2131L28.36 9.69561H27.5733H26.7866L26.7739 12.0855L26.7611 14.4796L26.6506 14.7135C26.4975 15.0409 26.3231 15.228 26.0084 15.3981C25.7661 15.5257 25.7065 15.5385 25.3791 15.5427C25.0772 15.5427 24.9794 15.5257 24.7795 15.4322C24.4733 15.2876 24.2352 15.0494 24.0778 14.7305L23.9545 14.4796L23.9418 12.0855L23.929 9.69561H23.1423H22.3556L22.3683 12.1918Z"
        fill="white"
      />
    </svg>
  );
};

export default WebGuru;
