export const FpsUpdate = "FpsUpdate";
export const ScrollBarTracker = "ScrollBarTracker";
export const ManualBarTracker = "ManualBarTracker";
export const LanguageChange = "LanguageChange";
export const QualityChange = "QualityChange";
export const MenuHideShow = "MenuHideShow";
export const LoadingSceneProgress = "LoadingSceneProgress";
export const LoadingSceneFinished = "LoadingSceneFinished";
export const ScrollActualScene = "ScrollActualScene";
export const QualityChangeNotification = "QualityChangeNotification";
export const QualityDetect = "QualityDetect";
