import React from 'react';

const Cssda = () => {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78632 2.71867L2.05368 5.43564L1.02768 5.43732H0V5.63031V5.8233H11.1569H22.3138V5.63031V5.43732H18.3708L14.426 5.43564L13.1184 4.14344L11.8107 2.84956L10.5282 4.14344L9.24406 5.43732H8.5282C8.13423 5.43732 7.81235 5.43396 7.81235 5.43061C7.81235 5.42725 9.03282 4.20386 10.5249 2.71195L13.2357 9.91067e-06L10.3774 0.00168809H7.51897L4.78632 2.71867Z"
        fill="white"
      />
      <path
        d="M4.04875 7.25648C3.57096 7.34878 3.23399 7.68777 3.13172 8.18451C3.10322 8.32213 3.09987 8.39261 3.10657 8.61749C3.11831 9.06053 3.20716 9.30554 3.43516 9.53377C3.65646 9.75362 3.88446 9.83585 4.28346 9.83585C4.53493 9.83585 4.71431 9.809 4.89202 9.7469L4.99596 9.70998V9.41462V9.11758L4.8149 9.18135C4.25831 9.37267 3.94313 9.26359 3.83752 8.84404C3.80734 8.72489 3.80734 8.37751 3.83752 8.25668C3.94481 7.81867 4.24322 7.70288 4.75119 7.89755C4.83334 7.92943 4.90207 7.95293 4.90543 7.95125C4.91213 7.94286 5.11331 7.42765 5.11331 7.41591C5.11331 7.4008 4.89872 7.32025 4.75287 7.28333C4.58019 7.23802 4.2164 7.22459 4.04875 7.25648Z"
        fill="white"
      />
      <path
        d="M7.2339 7.25814C6.92711 7.3152 6.73264 7.44945 6.62702 7.68104C6.56331 7.81866 6.55158 8.0687 6.60355 8.23484C6.68402 8.49328 6.80305 8.58894 7.39484 8.86752C7.56584 8.94807 7.62787 9.00681 7.62787 9.08736C7.62787 9.22665 7.55411 9.27196 7.32611 9.27028C7.1132 9.27028 6.82484 9.19812 6.61864 9.09407L6.55493 9.06219V9.37265V9.68479L6.64546 9.72507C6.81981 9.80227 6.91873 9.82073 7.22887 9.82912C7.46861 9.83583 7.5474 9.83247 7.65134 9.81066C7.81061 9.77374 7.98664 9.68815 8.09393 9.59082C8.25487 9.44481 8.34708 9.19141 8.32361 8.95478C8.30517 8.76851 8.2599 8.66782 8.13249 8.54027C8.0319 8.43958 7.98999 8.41273 7.6899 8.26505C7.31773 8.08213 7.25905 8.0385 7.25905 7.94788C7.25905 7.77167 7.51723 7.73978 7.89611 7.87068C8.00173 7.9076 8.09561 7.93948 8.10399 7.94284C8.11237 7.94452 8.16602 7.82872 8.2247 7.68608C8.31523 7.46456 8.32696 7.4226 8.30517 7.4075C8.26158 7.37394 7.9749 7.28499 7.82234 7.25814C7.64631 7.22793 7.39652 7.22793 7.2339 7.25814Z"
        fill="white"
      />
      <path
        d="M10.4528 7.25816C10.1577 7.31186 9.94816 7.45954 9.84254 7.68442C9.80398 7.76833 9.79895 7.79854 9.79895 7.97139C9.79895 8.19794 9.81739 8.26843 9.9096 8.40604C10.0001 8.54365 10.1208 8.62588 10.4796 8.80041C10.6405 8.87761 10.7897 8.96152 10.8132 8.98669C10.8434 9.01857 10.8551 9.04878 10.8551 9.09913C10.8551 9.17968 10.8015 9.23506 10.6908 9.26527C10.5433 9.30554 10.1728 9.23842 9.90624 9.12262L9.7738 9.06556L9.77716 9.37771L9.78219 9.69153L9.87774 9.72845C10.0705 9.80564 10.1594 9.82075 10.4561 9.82914C10.7897 9.83921 10.9172 9.81907 11.1133 9.72509C11.4151 9.58077 11.5676 9.31226 11.5458 8.96655C11.5391 8.86083 11.5257 8.80377 11.4888 8.72321C11.3966 8.53022 11.2726 8.43625 10.8384 8.22479C10.4913 8.05698 10.4209 7.97978 10.5115 7.86566C10.5986 7.7549 10.8736 7.76665 11.1988 7.89587C11.2759 7.92776 11.3413 7.95125 11.343 7.94957C11.343 7.94789 11.3916 7.82874 11.4486 7.6861C11.5693 7.38235 11.5777 7.41088 11.338 7.33368C11.0345 7.23635 10.716 7.20949 10.4528 7.25816Z"
        fill="white"
      />
      <path
        d="M13.11 8.53525V9.80228L13.6934 9.79556C14.3489 9.78885 14.3824 9.78382 14.6356 9.66131C14.8367 9.5623 15.0228 9.37937 15.1184 9.17967C15.2039 9.00682 15.2341 8.88935 15.2575 8.66279C15.3145 8.10731 15.1285 7.66763 14.7395 7.4394C14.5031 7.30011 14.2869 7.26654 13.618 7.26654H13.11V8.53525ZM14.1863 7.85391C14.3875 7.91096 14.5031 8.07878 14.5383 8.36072C14.5534 8.48155 14.5534 8.55707 14.54 8.66279C14.4847 9.07562 14.3305 9.2233 13.9398 9.23505L13.8057 9.23841L13.8007 8.53693L13.7973 7.83712H13.96C14.0505 7.83712 14.1527 7.84552 14.1863 7.85391Z"
        fill="white"
      />
      <path
        d="M17.3783 7.44611C17.3464 7.5468 17.1687 8.09556 16.9826 8.66783C16.7982 9.24009 16.6389 9.72844 16.6306 9.75361L16.6155 9.8006H16.9977H17.3816L17.3917 9.75361C17.3984 9.72844 17.4269 9.62271 17.4554 9.51866L17.5057 9.33071H17.9114H18.3154L18.3758 9.56062L18.4344 9.79221L18.8167 9.79724C19.1201 9.8006 19.1972 9.79724 19.1905 9.78046C19.1872 9.76871 18.9994 9.19813 18.7748 8.51175L18.3657 7.26654H17.9013H17.4386L17.3783 7.44611ZM17.9684 7.97474C17.9969 8.09724 18.0539 8.3238 18.0958 8.47819L18.1696 8.76013H17.9097H17.6499L17.6599 8.72153C17.7136 8.53021 17.8879 7.81195 17.8879 7.7851C17.8879 7.69951 17.9198 7.77335 17.9684 7.97474Z"
        fill="white"
      />
      <path
        d="M0 11.4368V11.6298H3.91959H7.8375L9.10323 12.8968C9.79897 13.5933 10.3757 14.1639 10.3857 14.1622C10.3941 14.1622 10.9792 13.5933 11.685 12.8968L12.9675 11.6315H13.6565L14.3439 11.6298L12.5752 13.3953C11.6012 14.3669 10.3908 15.5752 9.88279 16.0804L8.96073 17H11.8225H14.6842L15.7622 15.931C16.3556 15.3436 17.5728 14.1353 18.468 13.2459L20.0958 11.6298H21.2057H22.3138V11.4368V11.2438H11.1569H0V11.4368Z"
        fill="white"
      />
    </svg>
  );
};

export default Cssda;
